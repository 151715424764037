import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay  } from 'swiper';
import SlideContent from './SlideContent';
import fake from './fake';
import './styles.css';
import useAbout from './useAbout';

SwiperCore.use([Pagination, Autoplay ]);

const AboutCarousel = () => {

  const { data, isLoading, error } = useAbout();

  console.log('datadata', data)

  
  return (
    <div className='aboutCarousel'>
      <div className='container'>
        <Swiper
          pagination={{ clickable: true }}
          slidesPerView={1}
          spaceBetween={20}
          autoplay={{ delay: 3000 }}
          grabCursor
          breakpoints={{
            960: {
              slidesPerView: 2,
            },
          }}
        >
          {Array.isArray(data) && data.map(({ _id, Titulo, descripcion, Icono }) => (
            <SwiperSlide key={_id}>
              <SlideContent
                title={Titulo}
                description={descripcion}
                img={Icono.url}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default AboutCarousel;
