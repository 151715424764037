import './styles.css';

const SlideContent = ({ title, description, img }) => (
  <div className='aboutCarousel_slide'>
    <div className='aboutCarousel_slide-heading'>
      <img
        src={`https://testweb.recover.cl${img}`}
        className='aboutCarousel_slide-img'
        alt=''
      />
      <h3 className='aboutCarousel_slide-title'>{title}</h3>
    </div>
    <span className='aboutCarousel_slide-split' />
    <div className='aboutCarousel_slide-content'>
      <p className='aboutCarousel_slide-desc'>{description}</p>
    </div>
  </div>
);

export default SlideContent;
