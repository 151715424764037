import axios from 'axios';
import { useEffect, useState } from 'react';

function useAdvertising() {
  const [error, setError] = useState(undefined);
  const [data, setData] = useState(undefined);
  const [isLoading, setLoading] = useState(!data);

  async function requestQuery() {
    try {
      const { data } = await axios(
        `${process.env.REACT_APP_API_URL}/inicio-slider-1-s`
      );
      setData(data);
      setLoading(false);
    } catch (reason) {
      setError(reason);
      setLoading(false);
    }
  }

  useEffect(() => {
    requestQuery();
  }, []);

  return {
    isLoading,
    error,
    data,
  };
}

export default useAdvertising;
