import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink,
} from "react-router-dom";
import { Dropdown, DropdownButton } from "react-bootstrap";

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [personas, setPersonas] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const handleClick = () => {
    setOpenMenu(!openMenu);
    console.log("consol de handle", openMenu);
  };

  React.useEffect(() => {
    obtenerDatosPersonas();
    obtenerDatosEmpresas();
  }, []);

  const obtenerDatosPersonas = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/seguros?tipo_seguro=personas`
    );
    const personasJson = await data.json();
    setPersonas(personasJson);
    console.log("personas", personasJson);
  };

  const obtenerDatosEmpresas = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/seguros?tipo_seguro=empresas`
    );
    const empresasJson = await data.json();
    setEmpresas(empresasJson);
  };

  return (
    <div>
      <header id="header" style={{ position: "relative", height: 100, backgroundColor: '#f8fafd' }}>
        <div style={{ height:100}} className="container">
          <div className="paginas">
            <div style={{ height:100}} className="row">
              <div className="col-8 col-lg-4">
                <NavLink to="/" activeClassName="seleccionado">
                  <a className="logo">
                    <img
                      className="img-fluid"
                      src="https://www.recover.cl/web/img/logo.png"
                      alt="recover"
                    />
                  </a>
                </NavLink>
              </div>
              <div
                id="burgerMenu"
                style={{ fontSize: 30, color: "#1174ad" }}
                onClick={() => {
                  handleClick();
                }}
              >
                |||
              </div>
              <div
                className="closeMenu"
                style={{
                  top: openMenu === true ? 0 : "-550vw",
                }}
              >
                <p
                  className="xBtn"
                  onClick={() => {
                    handleClick();
                  }}
                  style={{
                    display: openMenu === true ? "flex" : "none",
                    fontSize: 30,
                    color: "#1174ad",
                    margin: 0,
                  }}
                >
                  X
                </p>
                <div>
                  <div>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Seguros Personas"
                    >
                      {personas
                        .sort((a, b) => a.order - b.order)
                        .map(({ order, titulo }) => (
                          <>
                            <div key={order}></div>
                          </>
                        ))}

                      {personas.map((item) => (
                        <Dropdown.Item
                          onClick={() => {
                            handleClick();
                          }}
                        >
                          <Link
                            to={{
                              pathname: `/seguros/${item.slug}`,
                              state: {
                                tipoSeguro: "Personas",
                                nombre: item.titulo_ICONO,
                                origen: "home",
                              },
                            }}
                          >
                            <ul>
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: item.titulo,
                                }}
                              ></li>
                            </ul>
                          </Link>
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div>
                    <DropdownButton
                      id="dropdown-basic-button"
                      title="Seguros Empresas"
                    >
                      {empresas.map((item) => (
                        <Dropdown.Item
                          onClick={() => {
                            handleClick();
                          }}
                        >
                          <Link
                            to={{
                              pathname: `/seguros/${item.slug}`,
                              state: {
                                tipoSeguro: "Empresas",
                                nombre: item.titulo_ICONO,
                                origen: "home",
                              },
                            }}
                          >
                            <ul>
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: item.titulo,
                                }}
                              ></li>
                            </ul>
                          </Link>
                        </Dropdown.Item>
                      ))}
                    </DropdownButton>
                  </div>
                  <div>
                    <Link                           onClick={() => {
                            handleClick();
                          }} to={{ pathname: "/contacto" }}>
                      <li className="ultimo">
                        <a>Contacto</a>
                      </li>
                    </Link>
                  </div>
                </div>
                <ul className="menu menuDesk">
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Seguros Personas"
                    bsPrefix="activeBtn"
                  >
                    {personas.map((item) => (
                      <Dropdown.Item
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/seguros/${item.slug}`,
                            state: {
                              tipoSeguro: "Personas",
                              nombre: item.titulo_ICONO,
                              origen: "home",
                            },
                          }}
                        >
                          <ul>
                            <li
                              dangerouslySetInnerHTML={{ __html: item.titulo }}
                            ></li>
                          </ul>
                        </Link>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <DropdownButton
                    id="dropdown-basic-button"
                    title="Seguros Empresas"
                    bsPrefix="activeBtn"
                  >
                    {empresas.map((item) => (
                      <Dropdown.Item
                        onClick={() => {
                          setOpenMenu(false);
                        }}
                      >
                        <Link
                          to={{
                            pathname: `/seguros/${item.slug}`,
                            state: {
                              tipoSeguro: "Empresas",
                              nombre: item.titulo_ICONO,
                              origen: "home",
                            },
                          }}
                        >
                          <ul>
                            <li
                              dangerouslySetInnerHTML={{ __html: item.titulo }}
                            ></li>
                          </ul>
                        </Link>
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                  <Link to="/contacto">
                    <li className="ultimo">
                      <a>Contacto</a>
                    </li>
                  </Link>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
      <style jsx>{`
        .img-fluid {
          position: relative;
          z-index: 10;
        }
        #burgerMenu {
          display: none;
        }

        .closeMenu {
          position: relative;
          background: #ededed;
          width: 100%;
          height: 100%;
          top: 0px;
          left: 0px;
          bottom: 0px;
          padding-top: 15%;
          padding-left: 40px;
          padding-bottom: 30px;
        }

        .menuDesk {
          position: relative;
          top: 529vw;
        }

        .xBtn {
          position: relative;
          right: -85.2vw;
          top: -74px;
        }

        .activeBtn:focus-visible,
        .activeBtn:active,
        .activeBtn:focus {
          border: none;
          outline: none;
          outline-width: 0px;
        }

        @media screen and (max-width: 2561px) {
          .menuDesk {
            top: 538vw;
          }
        }

        @media screen and (max-width: 2210px) {
          .menuDesk {
            top: 535vw;
          }
        }

        @media screen and (max-width: 1801px) {
          .menuDesk {
            top: 528vw;
          }
        

        @media screen and (max-width: 1441px) {
          .menuDesk {
            top: 527vw;
          }
        }

        @media screen and (max-width: 1025px) {
          .menuDesk {
            top: 520vw;
          }
        }

        @media screen and (max-width: 769px) {
          #burgerMenu {
            display: flex;
            position: relative;
            right: -22vw;
            transform: rotate(90deg);
          }

          .closeMenu {
            position: fixed;
          }

          .menuDesk {
            position: static;
          }

          .dropdown-menu {
            width: 85vw;
            height: 50vh;
            overflow-y: scroll;
          }
        }

        @media screen and (max-width: 510px) {
          .xBtn {
            right: -83.5vw;
            top: -7%;
          }
        }

        @media screen and (max-width: 426px) {
          .xBtn {
            right: -82vw;
            top: -12.2%;
          }

          .closeMenu {
            padding-top: 25%;
          }
        }

        @media screen and (max-width: 376px) {
          .xBtn {
            right: -80vw;
            top: -10%;
          }
        }

        @media screen and (max-width: 321px) {
          .xBtn {
            right: -78vw;
            top: -7%;
          }
        }
      `}</style>
    </div>
  );
};

export default Header;
