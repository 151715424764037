import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Autoplay  } from 'swiper';
import SlideContent from './SlideContent';
import useAdvertising from './useAdvertising';
import './styles.css';

SwiperCore.use([Pagination, Autoplay ]);

const AdvertisingCarousel = () => {
  const { data, isLoading, error } = useAdvertising();

  return (
    <div className='advertisingCarousel'>
      <div className='container'>
        {error ? (
          'Algo salio mal'
        ) : isLoading ? (
          'cargando'
        ) : (
          <div className='advertisingCarousel_carouselBox'>
            <Swiper pagination={{ clickable: true }} autoplay={{ delay: 3000 }}>
              {Array.isArray(data) &&
                data.map(
                  ({
                    titulo,
                    descripcion,
                    _id,
                    URL_boton,
                    texto_boton,
                    imagen_destacada,
                  }) => (
                    <SwiperSlide key={_id}>
                      <SlideContent
                        title={titulo}
                        description={descripcion}
                        moreInfLink={URL_boton}
                        moreInfText={texto_boton}
                        img={imagen_destacada.url}
                      />
                    </SwiperSlide>
                  )
                )}
            </Swiper>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvertisingCarousel;
