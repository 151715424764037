import React, { useState, useEffect } from "react";

const Somos = () => {
  const [banner, setBanner] = useState([]);

  useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = async () => {
    const data = await fetch(`${process.env.REACT_APP_API_URL}/banner-homes`);
    const bannerJson = await data.json();
    console.log("acuyá");
    console.log(bannerJson[0]);

    setBanner(bannerJson[0]);
  };

  return (
    <React.Fragment>
      <section className="somos nopdt nopdb">
        <div className="container">
          <div style={{ marginTop: 200 }} className="paginas">
            <div className="row">
              <div className="col-12">
                <div className="grupo grupoSomos">
                  <div className="row">
                    <div className="col-12 col-lg-6"> 
                      <h2>{banner.titulo_somos_expertos}</h2>
                    </div>
                    <div className="col-12 col-lg-6">
                      <p>{banner.descripcion_somos_expertos}</p>
                    </div>
                  </div>
                  <div className="cifras">
                    <div className="row justify-content-center rowSomos">
                      <div className="col-6 col-lg-4 contCol6">
                        <div className="modulo moduloCifras">
                          <span className="num">{banner.numero_consultas}</span>
                          <p>Consultas resueltas en un año</p>
                        </div>
                      </div>
                      <div className="col-6 col-lg-4">
                        <div className="modulo moduloCifras">
                          <span className="num">
                            {banner.numero_siniestros}
                          </span>
                          <p>Siniestros resueltos por año</p>
                        </div>
                      </div>
                      <div className="col-6 col-lg-4">
                        <div className="modulo moduloCifras">
                          <span className="num">
                            {banner.porcentaje_clientes}
                          </span>
                          <p>Clientes satisfechos</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <style jsx>{`
          @media screen and (max-width: 1025px) {
            .grupoSomos {
              height: 417px;
            }

            .cifras,
            .rowSomos,
            .contCol6 {
              height: 92px;
            }

            .moduloCifras {
              width: 280px;
              height: 92px;
            }
          }

          @media screen and (max-width: 800px) {
            .grupoSomos {
              height: 479px;
            }
          }

          @media screen and (max-width: 500px) {
            .grupoSomos {
              height: 591px;
            }

            .cifras,
            .rowSomos,
            .contCol6 {
              height: 130px;
            }

            .moduloCifras {
              width: 152px;
              height: 72px;
            }
          }

          @media screen and (max-width: 400px) {
            .grupoSomos {
              height: 680px;
            }

            .cifras,
            .rowSomos,
            .contCol6 {
              width: 300px;
              height: 150px;
            }

            .moduloCifras {
              width: 127px;
              height: 92px;
            }
          }

          @media screen and (max-width: 350px) {
            .grupoSomos {
              height: 720px;
            }

            .cifras,
            .rowSomos,
            .contCol6 {
              width: 260px;
            }

            .moduloCifras {
              width: 102px;
              height: 92px;
            }
          }
        `}</style>
      </section>
    </React.Fragment>
  );
};

export default Somos;
