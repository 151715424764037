import React, { useState } from "react";
import Banner from "../components/home/Banner";
import Nosotros from "../components/home/Nosotros";
import Slider from "../components/home/Slider";
import Slider2 from "../components/home/Slider2";
import Somos from "../components/home/Somos";
import Clientes from "../components/home/Clientes";
import Hablemos from "../components/common/Hablemos";
import RedesSociales from "../components/common/RedesSociales";
import Spinner from "react-bootstrap/Spinner";
import AdvertisingCarousel from '../components/home/AdvertisingCarousel';

const Inicio = () => {
  const [isLoading, setLoading] = useState(true);

  React.useEffect(() => {
    obtenerDatos(); 
  }, []);

  const obtenerDatos = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/inicio-slider-2-s`
    );
    const sliderData = await data.json();
    console.log("modiyi inicio ", sliderData);

    if (sliderData) {
      setLoading(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        >
          <Spinner animation="border" role="status" variant="primary">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <div>
      <Banner />
      <AdvertisingCarousel />
      <Nosotros />
      <Somos />
      <Clientes />
      <Hablemos />
      <RedesSociales />
        </div>
      )}
    </>
  );
};

export default Inicio;
