import ReactHtmlParser from 'react-html-parser';
import './styles.css';

const SlideContent = ({
  img,
  title,
  description,
  moreInfLink,
  moreInfText,
}) => (
  <div className='advertisingCarousel_slide'>
    <div>
      <h3 className='advertisingCarousel_slide-title'>
        {ReactHtmlParser(title)}
      </h3>
      <p className='advertisingCarousel_slide-desc'>
        {ReactHtmlParser(description)}
      </p>
      {moreInfLink && (
        <a href={moreInfLink} target="_blank" rel="noreferrer" className='boton text-white'>
          {moreInfText}
        </a>
      )}
    </div>
    <div className='text-center text-lg-right'>
      <img
        src={`https://testweb.recover.cl${img}`}
        className='img-fluid'
        alt={title}
      />
    </div>
  </div>
);

export default SlideContent;
