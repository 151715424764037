import React from 'react';
import AboutCarousel from './AboutCarousel';

const Nosotros = () => {
  React.useEffect(() => {
    obtenerDatos();
  }, []);

  const obtenerDatos = async () => {
    const data = await fetch(
      `${process.env.REACT_APP_API_URL}/inicio-slider-2-s`
    );
    const sliderData = await data.json();
    console.log('sliderdata');
    //console.log(sliderData[0].Icono.url)
  };

  return (
    <React.Fragment>
      <section className='nosotros nopdb'>
        <div className='container'>
          <div className='paginas'>
            <div className='row'>
              <div className='col-12'>
                <div className='titulo'>
                  <span className='sub'>Conoce más</span>
                  <h2>¿Por qué elegir Recover?</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='interior'>
          <div className='container'>
            <div className='paginas'>
              <div style={{ top: '-80px', position: 'relative' }}>
                <AboutCarousel />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Nosotros;
